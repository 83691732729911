import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import FrontPage from '../components/FrontPage'
import { navigate } from 'gatsby'
import getPlayStoreUrl from '../getPlayStoreUrl'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export default class PageNotFound extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera

    var phoneType = ''

    if (/android/i.test(userAgent)) {
      phoneType = 'Android'
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      phoneType = 'iOS'
    }

    if (phoneType == 'Android') {
      trackCustomEvent({
        // string - required - The object that was interacted with (e.g.video)
        category: 'Button',
        // string - required - Type of interaction (e.g. 'play')
        action: 'Click',
        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
        label: 'Android',
      })
      window.location.href = getPlayStoreUrl()
    } else if (phoneType == 'iOS') {
      trackCustomEvent({
        // string - required - The object that was interacted with (e.g.video)
        category: 'Button',
        // string - required - Type of interaction (e.g. 'play')
        action: 'Click',
        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
        label: 'iOS',
      })
      window.location.href =
        'https://itunes.apple.com/us/app/clerkie-money-advisor-finance/id1132917036?mt=8'
    }
  }

  render() {
    return (
      <Layout>
        <FrontPage></FrontPage>
      </Layout>
    )
  }
}
